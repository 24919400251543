import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import { randomBool, uuidv4 } from '../../../utils/utils';
import Explorer from '../Explorer/Explorer';
import { FolderOrFile } from '../Explorer/Helper/ExpolerInterfaces';
import './Test.css'

type Props = {}

export default function Test({ }: Props) {
    const ref = useRef<ITestRef>(null)

    function resetCount() {
        ref.current?.reset();
    }

    return (
        <div style={{ width: '100vw' }} className="test">
            abcdefg
            <TestRef ref={ref} />
            <button style={{ fontSize: '4em' }} onClick={resetCount}>reset</button>
            <button onClick={() => console.log('headers')}>a</button>
        </div>
    )
}


interface ITestRef {
    forceCount: (num: number) => void;
    reset(): void;
}


export const testExplorerItems = [
    {
        name: 'foo.ts',
        fileContents: '',
        id: uuidv4(),
    }, {
        name: 'bar',
        id: uuidv4(),
        subItems: [{
            name: 'a.js',
            fileContents: '',
            id: uuidv4(),
        },
        {
            name: 'b.js',
            fileContents: '',
            id: uuidv4(),
        },
        {
            name: 'conf',
            subItems: [
                {
                    name: 'foo.ts',
                    fileContents: '',
                    id: uuidv4(),
                }, {
                    name: 'bar',
                    subItems: [{
                        name: 'a.js',
                        fileContents: '',
                        id: uuidv4(),
                    },
                    {
                        name: 'b.js',
                        fileContents: '',
                        id: uuidv4(),
                    },
                    ],
                    id: uuidv4(),
                }
            ],
            id: uuidv4(),
        }
        ]
    }
];

export const TestRef = React.forwardRef<ITestRef>((props: Props, ref) => {
    const [count, setCount] = useState(0);
    const [items, setItems] = useState<FolderOrFile[]>([{ name: '0 x', fileContents: 'foo.txt' }]);
    useImperativeHandle(ref, () => {
        return {
            forceCount: forceCount,
            reset: () => setCount(0)
        }
    })
    function forceCount(num: number) {
        setCount(num);
    }

    return (
        <>
            <Explorer items={testExplorerItems}/>
            <button onClick={(e) => {
                setItems(prev => [...prev, {
                    name: `${prev.length + 1} x`,
                    fileContents: ''
                }])
            }}>add</button>
            {
                (() => {
                    let a = 0, b = 0;
                    for (let i = 0; i < 10; i++) {
                        randomBool() ? a++ : b++
                    }
                    return <h2>{a} : {b}</h2>
                })()
            }

            <div>{items.length}</div>
        </>
    )
})

